import { Injectable } from '@angular/core';
import {HttpHelperService} from "./http-helper.service";


@Injectable()
export class AuthService {


  constructor(private httpHelper: HttpHelperService) {}



  secureLogin(formData: any){
    return this.httpHelper.post('authaccess', formData);
  }

  createRegistrationSession(formData: any) {
    return this.httpHelper.post('signup/registration/createRegistrationSession', formData);
  }

  verifyActivationCode(formData: any) {
    return this.httpHelper.post('signup/registration/verifyActivationCode', formData);
  }

  processRegistration(formData: any) {
    return this.httpHelper.post('signup/registration/processRegistration', formData);
  }


  routeToLogin(){
    window.location.href = 'https://minside.nabonett.no/';
  }



}
