import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {FormControl, FormGroup} from "@angular/forms";
import {CustomValidators} from "../../modules/shared/services/custom-validators";
import {PHONE_CODES_WITH_COUNTRYIDS, SharedService} from "../../modules/shared/services/shared.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  phoneCodesWIthCountryId = PHONE_CODES_WITH_COUNTRYIDS;
  fg = new FormGroup({
    name: new FormControl('', [CustomValidators.customRequired]),
    email: new FormControl('',[CustomValidators.customRequired, CustomValidators.customEmail]),
    phone: new FormControl('',[]),
    countryId: new FormControl(163,[])
  })

  get ctrlName(): FormControl {  return this.fg.controls['name'] as FormControl; }
  get ctrlEmail(): FormControl {  return this.fg.controls['email'] as FormControl; }
  constructor(public authService: AuthService,
              private sharedService: SharedService,
              private router: Router
              ) { }

  ngOnInit(): void {
  }


  wasValidated = false;
  loadingData = false;
  onSignUpClick(){
    if(this.fg.valid){
      const formVal = this.fg.value;
      this.loadingData = true;
      this.authService.createRegistrationSession(formVal).subscribe(response => {
        console.log('CreateRegistrationSession', response);
        this.loadingData = false;
        this.router.navigate(['/verification'],{ queryParams: {mail: this.ctrlEmail.value}});
      }, error => {
        console.log('Error', error);
        this.loadingData = false;
      });
    }else{
      this.sharedService.validateAllFormFields(this.fg);
      this.sharedService.debugFormErrors(this.fg);
    }

  this.wasValidated = true;

  }
}
