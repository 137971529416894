<div class="container h-100">
  <div class="row h-100 justify-content-center align-items-center">
    <div class="col-12 form-horizontal min-signup-form mb-55">
      <div class=" row">
        <div class="col-md-12 pb-3 min-heading-sec">
          <h3>Passord endret</h3>
        </div>
      </div>


      <div class=" row">
        <p class="col-md-12 mb-5 mt-4">Byttingen av passordet ditt er velykket. Vennligst logg inn med ditt nye passord.

        </p>
      </div>
      <div class=" row">
        <div class="col-md-12 text-center">

          <button (click)="authService.routeToLogin()" mat-raised-button  class="btn-submit btn-yellow mb-5">Logg Inn</button>
        </div>

      </div>

    </div>
  </div>
</div>
<form class="form-horizontal"  >


</form>
