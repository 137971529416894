<div class="container h-100">
  <div class="row h-100 justify-content-center align-items-center">
    <form class="col-12 form-horizontal min-signup-form mb-55">
      <div class=" row">
        <div class="col-md-12 pb-3 min-heading-sec">
          <h3>Glemt passord</h3>
        </div>
      </div>
      <div class=" row"  [formGroup]="fg">
        <div class="col-md-12 pb-4">
          <label>E-post<span class="required-star">*</span></label>
          <div class="input-group with-focus" >
            <input  formControlName="email"
                    [ngClass]="{'is-invalid': wasValidated && ctrlEmail.dirty && ctrlEmail.invalid, 'is-valid': wasValidated && ctrlEmail.dirty && ctrlEmail.valid}"
                    placeholder="eksempel@epost.no" type="text" class="form-control" required>
          </div>
        </div>
      </div>

      <div class=" row">
        <p class="col-md-12 mb-5 mt-4">Du vil få tilsendt en epost med lenke som du kan bruke til å lage nytt passord på din konto. Denne er gyldig i 1 time.
        </p>
      </div>
      <div class=" row">
        <div class="col-md-12 text-center">
          <button (click)="onNewPasswordClick()" [disabled]="loadingData" [ngClass]="{'ajax-loader-2' : loadingData}"  mat-raised-button  class="btn-submit btn-yellow mb-5">Nytt passord</button>
          <a class="mt-5 link-blue"  (click)="authService.routeToLogin()">Tilbake til innlogging</a>
        </div>

      </div>

    </form>
  </div>
</div>
<form class="form-horizontal"  >


</form>
