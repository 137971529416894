import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-verification-sent',
  templateUrl: './verification-sent.component.html',
  styleUrls: ['./verification-sent.component.scss']
})
export class VerificationSentComponent implements OnInit {
  mail: '';
  constructor(public authService: AuthService,
              private activatedRoute: ActivatedRoute,
  ) {

    const queryParams: any = this.activatedRoute.snapshot.queryParams;
    this.mail = queryParams.mail;
  }
  ngOnInit(): void {
  }

}
