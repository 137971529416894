<div class="container h-100">
  <div class="row h-100 justify-content-center align-items-center">
    <form class="col-12 form-horizontal min-signup-form mb-55">
      <div class=" row">
        <div class="col-md-12 pb-3 min-heading-sec">
          <h3>Bekreft e-posten din</h3>
        </div>
      </div>


      <div class=" row">
        <p class="col-md-12 mb-5 mt-4">Vi har sendt en epost til {{mail}} med aktiveringlenke. Følg lenken for å aktivere kontoen din. Lenken er kun gyldig i 1 time.

        </p>
      </div>
      <div class=" row">
        <div class="col-md-12 text-center">
          <a class="mt-5 link-blue" (click)="authService.routeToLogin()">Logg Inn</a>
        </div>

      </div>

    </form>
  </div>
</div>
<form class="form-horizontal"  >


</form>
