import { Injectable } from '@angular/core';
import {Router} from "@angular/router";

import {FormControl, FormGroup} from "@angular/forms";
import {ConfirmationPopupComponent} from "../components/confirmation-popup/confirmation-popup.component";
import {Modal, overlayConfigFactory} from "ngx-modialog-7";
import {BSModalContext} from "ngx-modialog-7/plugins/bootstrap";

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  SEC_VAL = "forvaltning";
  SESSION_KEY = "app-authentication";
  SESSION_VAL = "SELGW-RHQQ2-YXRKT-2b738-!@#$$%";
  constructor( private router: Router,
               private modal: Modal) { }





  public validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public debugFormErrors(formGroup?: FormGroup) {
    if (formGroup){
      Object.keys(formGroup.controls).forEach(key => {
        const formItem = formGroup.controls[key];
        if(formItem instanceof FormGroup) {
          this.debugFormErrors(formItem);
        }
        const controlErrors: any = formItem.errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            // console.log('control: ' + key + ', Error: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
        }
      });
    }

  }

  public base64ToArrayBuffer(base64: any) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }


  public saveAs(blob: any, filename: any) {
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  }
  // -- function to display confirmation dialog
  showConfirmation(paramObj: any, proceedCallBackFunc: any, cancelCallBackFunc?: any) {
    const dialog = this.modal.open(ConfirmationPopupComponent, overlayConfigFactory(paramObj, BSModalContext));
    dialog.result.then((data: any) => {
      if (data) {
        proceedCallBackFunc.call();
      } else if (cancelCallBackFunc) {
        cancelCallBackFunc.call();
      }
    });
  }
}

export const PHONE_CODE_DEFAULT_COUNTRYID = 163;
export const PHONE_CODES_WITH_COUNTRYIDS = [
  {
    "Id": 1,
    "Name": "Afghanistan",
    "CountryCode": "AF",
    "DialingCode": "+93"
  },
  {
    "Id": 2,
    "Name": "Albania",
    "CountryCode": "AL",
    "DialingCode": "+355"
  },
  {
    "Id": 3,
    "Name": "Algeria",
    "CountryCode": "DZ",
    "DialingCode": "+213"
  },
  {
    "Id": 4,
    "Name": "American Samoa",
    "CountryCode": "AS",
    "DialingCode": "+1-684"
  },
  {
    "Id": 5,
    "Name": "Andorra, Principality of",
    "CountryCode": "AD",
    "DialingCode": "+376"
  },
  {
    "Id": 6,
    "Name": "Angola",
    "CountryCode": "AO",
    "DialingCode": "+244"
  },
  {
    "Id": 7,
    "Name": "Anguilla",
    "CountryCode": "AI",
    "DialingCode": "+1-264"
  },
  {
    "Id": 8,
    "Name": "Antarctica",
    "CountryCode": "AQ",
    "DialingCode": "+672"
  },
  {
    "Id": 9,
    "Name": "Antigua and Barbuda",
    "CountryCode": "AG",
    "DialingCode": "+1-268"
  },
  {
    "Id": 10,
    "Name": "Argentina",
    "CountryCode": "AR",
    "DialingCode": "+54"
  },
  {
    "Id": 11,
    "Name": "Armenia",
    "CountryCode": "AM",
    "DialingCode": "+374"
  },
  {
    "Id": 12,
    "Name": "Aruba",
    "CountryCode": "AW",
    "DialingCode": "+297"
  },
  {
    "Id": 13,
    "Name": "Australia",
    "CountryCode": "AU",
    "DialingCode": "+61"
  },
  {
    "Id": 14,
    "Name": "Austria",
    "CountryCode": "AT",
    "DialingCode": "+43"
  },
  {
    "Id": 15,
    "Name": "Azerbaijan or Azerbaidjan (Former Azerbaijan Soviet Socialist Republic)",
    "CountryCode": "AZ",
    "DialingCode": "+994"
  },
  {
    "Id": 16,
    "Name": "Bahamas, Commonwealth of The",
    "CountryCode": "BS",
    "DialingCode": "+1-242"
  },
  {
    "Id": 17,
    "Name": "Bahrain, Kingdom of (Former Dilmun)",
    "CountryCode": "BH",
    "DialingCode": "+973"
  },
  {
    "Id": 18,
    "Name": "Bangladesh (Former East Pakistan)",
    "CountryCode": "BD",
    "DialingCode": "+880"
  },
  {
    "Id": 19,
    "Name": "Barbados",
    "CountryCode": "BB",
    "DialingCode": "+1-246"
  },
  {
    "Id": 20,
    "Name": "Belarus (Former Belorussian [Byelorussian] Soviet Socialist Republic)",
    "CountryCode": "BY",
    "DialingCode": "+375"
  },
  {
    "Id": 21,
    "Name": "Belgium",
    "CountryCode": "BE",
    "DialingCode": "+32"
  },
  {
    "Id": 22,
    "Name": "Belize (Former British Honduras)",
    "CountryCode": "BZ",
    "DialingCode": "+501"
  },
  {
    "Id": 23,
    "Name": "Benin (Former Dahomey)",
    "CountryCode": "BJ",
    "DialingCode": "+229"
  },
  {
    "Id": 24,
    "Name": "Bermuda",
    "CountryCode": "BM",
    "DialingCode": "+1-441"
  },
  {
    "Id": 25,
    "Name": "Bhutan, Kingdom of",
    "CountryCode": "BT",
    "DialingCode": "+975"
  },
  {
    "Id": 26,
    "Name": "Bolivia",
    "CountryCode": "BO",
    "DialingCode": "+591"
  },
  {
    "Id": 27,
    "Name": "Bosnia and Herzegovina",
    "CountryCode": "BA",
    "DialingCode": "+387"
  },
  {
    "Id": 28,
    "Name": "Botswana (Former Bechuanaland)",
    "CountryCode": "BW",
    "DialingCode": "+267"
  },
  {
    "Id": 30,
    "Name": "Brazil",
    "CountryCode": "BR",
    "DialingCode": "+55"
  },
  {
    "Id": 32,
    "Name": "Brunei (Negara Brunei Darussalam)",
    "CountryCode": "BN",
    "DialingCode": "+673"
  },
  {
    "Id": 33,
    "Name": "Bulgaria",
    "CountryCode": "BG",
    "DialingCode": "+359"
  },
  {
    "Id": 34,
    "Name": "Burkina Faso (Former Upper Volta)",
    "CountryCode": "BF",
    "DialingCode": "+226"
  },
  {
    "Id": 35,
    "Name": "Burundi (Former Urundi)",
    "CountryCode": "BI",
    "DialingCode": "+257"
  },
  {
    "Id": 36,
    "Name": "Cambodia, Kingdom of (Former Khmer Republic, Kampuchea Republic)",
    "CountryCode": "KH",
    "DialingCode": "+855"
  },
  {
    "Id": 37,
    "Name": "Cameroon (Former French Cameroon)",
    "CountryCode": "CM",
    "DialingCode": "+237"
  },
  {
    "Id": 38,
    "Name": "Canada",
    "CountryCode": "CA",
    "DialingCode": "+1"
  },
  {
    "Id": 39,
    "Name": "Cape Verde",
    "CountryCode": "CV",
    "DialingCode": "+238"
  },
  {
    "Id": 40,
    "Name": "Cayman Islands",
    "CountryCode": "KY",
    "DialingCode": "+1-345"
  },
  {
    "Id": 41,
    "Name": "Central African Republic",
    "CountryCode": "CF",
    "DialingCode": "+236"
  },
  {
    "Id": 42,
    "Name": "Chad",
    "CountryCode": "TD",
    "DialingCode": "+235"
  },
  {
    "Id": 43,
    "Name": "Chile",
    "CountryCode": "CL",
    "DialingCode": "+56"
  },
  {
    "Id": 44,
    "Name": "China",
    "CountryCode": "CN",
    "DialingCode": "+86"
  },
  {
    "Id": 45,
    "Name": "Christmas Island",
    "CountryCode": "CX",
    "DialingCode": "+53"
  },
  {
    "Id": 46,
    "Name": "Cocos (Keeling) Islands",
    "CountryCode": "CC",
    "DialingCode": "+61"
  },
  {
    "Id": 47,
    "Name": "Colombia",
    "CountryCode": "CO",
    "DialingCode": "+57"
  },
  {
    "Id": 48,
    "Name": "Comoros, Union of the",
    "CountryCode": "KM",
    "DialingCode": "+269"
  },
  {
    "Id": 49,
    "Name": "Congo, Democratic Republic of the (Former Zaire)",
    "CountryCode": "CD",
    "DialingCode": "+243"
  },
  {
    "Id": 50,
    "Name": "Congo, Republic of the",
    "CountryCode": "CG",
    "DialingCode": "+242"
  },
  {
    "Id": 51,
    "Name": "Cook Islands (Former Harvey Islands)",
    "CountryCode": "CK",
    "DialingCode": "+682"
  },
  {
    "Id": 52,
    "Name": "Costa Rica",
    "CountryCode": "CR",
    "DialingCode": "+506"
  },
  {
    "Id": 53,
    "Name": "Cote D`Ivoire (Former Ivory Coast)",
    "CountryCode": "CI",
    "DialingCode": "+225"
  },
  {
    "Id": 54,
    "Name": "Croatia (Hrvatska)",
    "CountryCode": "HR",
    "DialingCode": "+385"
  },
  {
    "Id": 55,
    "Name": "Cuba",
    "CountryCode": "CU",
    "DialingCode": "+53"
  },
  {
    "Id": 56,
    "Name": "Cyprus",
    "CountryCode": "CY",
    "DialingCode": "+357"
  },
  {
    "Id": 57,
    "Name": "Czech Republic",
    "CountryCode": "CZ",
    "DialingCode": "+420"
  },
  {
    "Id": 59,
    "Name": "Denmark",
    "CountryCode": "DK",
    "DialingCode": "+45"
  },
  {
    "Id": 60,
    "Name": "Djibouti (Former French Territory of the Afars and Issas, French Somaliland)",
    "CountryCode": "DJ",
    "DialingCode": "+253"
  },
  {
    "Id": 61,
    "Name": "Dominica",
    "CountryCode": "DM",
    "DialingCode": "+1-767"
  },
  {
    "Id": 63,
    "Name": "East Timor (Former Portuguese Timor)",
    "CountryCode": "TP",
    "DialingCode": "+670"
  },
  {
    "Id": 64,
    "Name": "Ecuador",
    "CountryCode": "EC",
    "DialingCode": "+593 "
  },
  {
    "Id": 65,
    "Name": "Egypt (Former United Arab Republic - with Syria)",
    "CountryCode": "EG",
    "DialingCode": "+20"
  },
  {
    "Id": 66,
    "Name": "El Salvador",
    "CountryCode": "SV",
    "DialingCode": "+503"
  },
  {
    "Id": 67,
    "Name": "Equatorial Guinea (Former Spanish Guinea)",
    "CountryCode": "GQ",
    "DialingCode": "+240"
  },
  {
    "Id": 68,
    "Name": "Eritrea (Former Eritrea Autonomous Region in Ethiopia)",
    "CountryCode": "ER",
    "DialingCode": "+291"
  },
  {
    "Id": 69,
    "Name": "Estonia (Former Estonian Soviet Socialist Republic)",
    "CountryCode": "EE",
    "DialingCode": "+372"
  },
  {
    "Id": 70,
    "Name": "Ethiopia (Former Abyssinia, Italian East Africa)",
    "CountryCode": "ET",
    "DialingCode": "+251"
  },
  {
    "Id": 71,
    "Name": "Falkland Islands (Islas Malvinas)",
    "CountryCode": "FK",
    "DialingCode": "+500"
  },
  {
    "Id": 72,
    "Name": "Faroe Islands",
    "CountryCode": "FO",
    "DialingCode": "+298"
  },
  {
    "Id": 73,
    "Name": "Fiji",
    "CountryCode": "FJ",
    "DialingCode": "+679"
  },
  {
    "Id": 74,
    "Name": "Finland",
    "CountryCode": "FI",
    "DialingCode": "+358"
  },
  {
    "Id": 75,
    "Name": "France",
    "CountryCode": "FR",
    "DialingCode": "+33"
  },
  {
    "Id": 76,
    "Name": "French Guiana or French Guyana",
    "CountryCode": "GF",
    "DialingCode": "+594"
  },
  {
    "Id": 77,
    "Name": "French Polynesia (Former French Colony of Oceania)",
    "CountryCode": "PF",
    "DialingCode": "+689"
  },
  {
    "Id": 79,
    "Name": "Gabon (Gabonese Republic)",
    "CountryCode": "GA",
    "DialingCode": "+241"
  },
  {
    "Id": 80,
    "Name": "Gambia, The",
    "CountryCode": "GM",
    "DialingCode": "+220"
  },
  {
    "Id": 81,
    "Name": "Georgia (Former Georgian Soviet Socialist Republic)",
    "CountryCode": "GE",
    "DialingCode": "+995"
  },
  {
    "Id": 82,
    "Name": "Germany",
    "CountryCode": "DE",
    "DialingCode": "+49"
  },
  {
    "Id": 83,
    "Name": "Ghana (Former Gold Coast)",
    "CountryCode": "GH",
    "DialingCode": "+233"
  },
  {
    "Id": 84,
    "Name": "Gibraltar",
    "CountryCode": "GI",
    "DialingCode": "+350"
  },
  {
    "Id": 86,
    "Name": "Greece",
    "CountryCode": "GR",
    "DialingCode": "+30"
  },
  {
    "Id": 87,
    "Name": "Greenland",
    "CountryCode": "GL",
    "DialingCode": "+299"
  },
  {
    "Id": 88,
    "Name": "Grenada",
    "CountryCode": "GD",
    "DialingCode": "+1-473"
  },
  {
    "Id": 89,
    "Name": "Guadeloupe",
    "CountryCode": "GP",
    "DialingCode": "+590"
  },
  {
    "Id": 90,
    "Name": "Guam",
    "CountryCode": "GU",
    "DialingCode": "+1-671"
  },
  {
    "Id": 91,
    "Name": "Guatemala",
    "CountryCode": "GT",
    "DialingCode": "+502"
  },
  {
    "Id": 92,
    "Name": "Guinea (Former French Guinea)",
    "CountryCode": "GN",
    "DialingCode": "+224"
  },
  {
    "Id": 93,
    "Name": "Guinea-Bissau (Former Portuguese Guinea)",
    "CountryCode": "GW",
    "DialingCode": "+245"
  },
  {
    "Id": 94,
    "Name": "Guyana (Former British Guiana)",
    "CountryCode": "GY",
    "DialingCode": "+592"
  },
  {
    "Id": 95,
    "Name": "Haiti",
    "CountryCode": "HT",
    "DialingCode": "+509"
  },
  {
    "Id": 98,
    "Name": "Honduras",
    "CountryCode": "HN",
    "DialingCode": "+504"
  },
  {
    "Id": 99,
    "Name": "Hong Kong",
    "CountryCode": "HK",
    "DialingCode": "+852"
  },
  {
    "Id": 100,
    "Name": "Hungary",
    "CountryCode": "HU",
    "DialingCode": "+36"
  },
  {
    "Id": 101,
    "Name": "Iceland",
    "CountryCode": "IS",
    "DialingCode": "+354"
  },
  {
    "Id": 102,
    "Name": "India",
    "CountryCode": "IN",
    "DialingCode": "+91"
  },
  {
    "Id": 103,
    "Name": "Indonesia (Former Netherlands East Indies; Dutch East Indies)",
    "CountryCode": "ID",
    "DialingCode": "+62"
  },
  {
    "Id": 104,
    "Name": "Iran, Islamic Republic of",
    "CountryCode": "IR",
    "DialingCode": "+98"
  },
  {
    "Id": 105,
    "Name": "Iraq",
    "CountryCode": "IQ",
    "DialingCode": "+964"
  },
  {
    "Id": 106,
    "Name": "Ireland",
    "CountryCode": "IE",
    "DialingCode": "+353"
  },
  {
    "Id": 107,
    "Name": "Israel",
    "CountryCode": "IL",
    "DialingCode": "+972"
  },
  {
    "Id": 108,
    "Name": "Italy",
    "CountryCode": "IT",
    "DialingCode": "+39"
  },
  {
    "Id": 109,
    "Name": "Jamaica",
    "CountryCode": "JM",
    "DialingCode": "+1-876"
  },
  {
    "Id": 110,
    "Name": "Japan",
    "CountryCode": "JP",
    "DialingCode": "+81"
  },
  {
    "Id": 111,
    "Name": "Jordan (Former Transjordan)",
    "CountryCode": "JO",
    "DialingCode": "+962"
  },
  {
    "Id": 112,
    "Name": "Kazakstan or Kazakhstan (Former Kazakh Soviet Socialist Republic)",
    "CountryCode": "KZ",
    "DialingCode": "+7"
  },
  {
    "Id": 113,
    "Name": "Kenya (Former British East Africa)",
    "CountryCode": "KE",
    "DialingCode": "+254"
  },
  {
    "Id": 114,
    "Name": "Kiribati (Pronounced keer-ree-bahss) (Former Gilbert Islands)",
    "CountryCode": "KI",
    "DialingCode": "+686"
  },
  {
    "Id": 115,
    "Name": "Korea, Democratic People`s Republic of (North Korea)",
    "CountryCode": "KP",
    "DialingCode": "+850"
  },
  {
    "Id": 116,
    "Name": "Korea, Republic of (South Korea)",
    "CountryCode": "KR",
    "DialingCode": "+82"
  },
  {
    "Id": 117,
    "Name": "Kuwait",
    "CountryCode": "KW",
    "DialingCode": "+965"
  },
  {
    "Id": 118,
    "Name": "Kyrgyzstan (Kyrgyz Republic) (Former Kirghiz Soviet Socialist Republic)",
    "CountryCode": "KG",
    "DialingCode": "+996"
  },
  {
    "Id": 119,
    "Name": "Lao People`s Democratic Republic (Laos)",
    "CountryCode": "LA",
    "DialingCode": "+856"
  },
  {
    "Id": 120,
    "Name": "Latvia (Former Latvian Soviet Socialist Republic)",
    "CountryCode": "LV",
    "DialingCode": "+371"
  },
  {
    "Id": 121,
    "Name": "Lebanon",
    "CountryCode": "LB",
    "DialingCode": "+961"
  },
  {
    "Id": 122,
    "Name": "Lesotho (Former Basutoland)",
    "CountryCode": "LS",
    "DialingCode": "+266"
  },
  {
    "Id": 123,
    "Name": "Liberia",
    "CountryCode": "LR",
    "DialingCode": "+231"
  },
  {
    "Id": 124,
    "Name": "Libya (Libyan Arab Jamahiriya)",
    "CountryCode": "LY",
    "DialingCode": "+218"
  },
  {
    "Id": 125,
    "Name": "Liechtenstein",
    "CountryCode": "LI",
    "DialingCode": "+423"
  },
  {
    "Id": 126,
    "Name": "Lithuania (Former Lithuanian Soviet Socialist Republic)",
    "CountryCode": "LT",
    "DialingCode": "+370"
  },
  {
    "Id": 127,
    "Name": "Luxembourg",
    "CountryCode": "LU",
    "DialingCode": "+352"
  },
  {
    "Id": 128,
    "Name": "Macau",
    "CountryCode": "MO",
    "DialingCode": "+853"
  },
  {
    "Id": 129,
    "Name": "Macedonia, The Former Yugoslav Republic of",
    "CountryCode": "MK",
    "DialingCode": "+389"
  },
  {
    "Id": 130,
    "Name": "Madagascar (Former Malagasy Republic)",
    "CountryCode": "MG",
    "DialingCode": "+261"
  },
  {
    "Id": 131,
    "Name": "Malawi (Former British Central African Protectorate, Nyasaland)",
    "CountryCode": "MW",
    "DialingCode": "+265"
  },
  {
    "Id": 132,
    "Name": "Malaysia",
    "CountryCode": "MY",
    "DialingCode": "+60"
  },
  {
    "Id": 133,
    "Name": "Maldives",
    "CountryCode": "MV",
    "DialingCode": "+960"
  },
  {
    "Id": 134,
    "Name": "Mali (Former French Sudan and Sudanese Republic)",
    "CountryCode": "ML",
    "DialingCode": "+223"
  },
  {
    "Id": 135,
    "Name": "Malta",
    "CountryCode": "MT",
    "DialingCode": "+356"
  },
  {
    "Id": 136,
    "Name": "Marshall Islands (Former Marshall Islands District - Trust Territory of the Pacific Islands)",
    "CountryCode": "MH",
    "DialingCode": "+692"
  },
  {
    "Id": 137,
    "Name": "Martinique (French)",
    "CountryCode": "MQ",
    "DialingCode": "+596"
  },
  {
    "Id": 138,
    "Name": "Mauritania",
    "CountryCode": "MR",
    "DialingCode": "+222"
  },
  {
    "Id": 139,
    "Name": "Mauritius",
    "CountryCode": "MU",
    "DialingCode": "+230"
  },
  {
    "Id": 140,
    "Name": "Mayotte (Territorial Collectivity of Mayotte)",
    "CountryCode": "YT",
    "DialingCode": "+269"
  },
  {
    "Id": 141,
    "Name": "Mexico",
    "CountryCode": "MX",
    "DialingCode": "+52"
  },
  {
    "Id": 142,
    "Name": "Micronesia, Federated States of (Former Ponape, Truk, and Yap Districts - Trust Territory of the Pacific Islands)",
    "CountryCode": "FM",
    "DialingCode": "+691"
  },
  {
    "Id": 143,
    "Name": "Moldova, Republic of",
    "CountryCode": "MD",
    "DialingCode": "+373"
  },
  {
    "Id": 144,
    "Name": "Monaco, Principality of",
    "CountryCode": "MC",
    "DialingCode": "+377"
  },
  {
    "Id": 145,
    "Name": "Mongolia (Former Outer Mongolia)",
    "CountryCode": "MN",
    "DialingCode": "+976"
  },
  {
    "Id": 146,
    "Name": "Montserrat",
    "CountryCode": "MS",
    "DialingCode": "+1-664"
  },
  {
    "Id": 147,
    "Name": "Morocco",
    "CountryCode": "MA",
    "DialingCode": "+212"
  },
  {
    "Id": 148,
    "Name": "Mozambique (Former Portuguese East Africa)",
    "CountryCode": "MZ",
    "DialingCode": "+258"
  },
  {
    "Id": 149,
    "Name": "Myanmar, Union of (Former Burma)",
    "CountryCode": "MM",
    "DialingCode": "+95"
  },
  {
    "Id": 150,
    "Name": "Namibia (Former German Southwest Africa, South-West Africa)",
    "CountryCode": "NA",
    "DialingCode": "+264"
  },
  {
    "Id": 151,
    "Name": "Nauru (Former Pleasant Island)",
    "CountryCode": "NR",
    "DialingCode": "+674"
  },
  {
    "Id": 152,
    "Name": "Nepal",
    "CountryCode": "NP",
    "DialingCode": "+977"
  },
  {
    "Id": 153,
    "Name": "Netherlands",
    "CountryCode": "NL",
    "DialingCode": "+31"
  },
  {
    "Id": 154,
    "Name": "Netherlands Antilles (Former Curacao and Dependencies)",
    "CountryCode": "AN",
    "DialingCode": "+599"
  },
  {
    "Id": 155,
    "Name": "New Caledonia",
    "CountryCode": "NC",
    "DialingCode": "+687"
  },
  {
    "Id": 156,
    "Name": "New Zealand (Aotearoa)",
    "CountryCode": "NZ",
    "DialingCode": "+64"
  },
  {
    "Id": 157,
    "Name": "Nicaragua",
    "CountryCode": "NI",
    "DialingCode": "+505"
  },
  {
    "Id": 158,
    "Name": "Niger",
    "CountryCode": "NE",
    "DialingCode": "+227"
  },
  {
    "Id": 159,
    "Name": "Nigeria",
    "CountryCode": "NG",
    "DialingCode": "+234"
  },
  {
    "Id": 160,
    "Name": "Niue (Former Savage Island)",
    "CountryCode": "NU",
    "DialingCode": "+683"
  },
  {
    "Id": 161,
    "Name": "Norfolk Island",
    "CountryCode": "NF",
    "DialingCode": "+672"
  },
  {
    "Id": 162,
    "Name": "Northern Mariana Islands (Former Mariana Islands District - Trust Territory of the Pacific Islands)",
    "CountryCode": "MP",
    "DialingCode": "+1-670"
  },
  {
    "Id": 163,
    "Name": "Norway",
    "CountryCode": "NO",
    "DialingCode": "+47"
  },
  {
    "Id": 164,
    "Name": "Oman, Sultanate of (Former Muscat and Oman)",
    "CountryCode": "OM",
    "DialingCode": "+968"
  },
  {
    "Id": 165,
    "Name": "Pakistan (Former West Pakistan)",
    "CountryCode": "PK",
    "DialingCode": "+92"
  },
  {
    "Id": 166,
    "Name": "Palau (Former Palau District - Trust Terriroty of the Pacific Islands)",
    "CountryCode": "PW",
    "DialingCode": "+680"
  },
  {
    "Id": 167,
    "Name": "Palestinian State (Proposed)",
    "CountryCode": "PS",
    "DialingCode": "+970"
  },
  {
    "Id": 168,
    "Name": "Panama",
    "CountryCode": "PA",
    "DialingCode": "+507"
  },
  {
    "Id": 169,
    "Name": "Papua New Guinea (Former Territory of Papua and New Guinea)",
    "CountryCode": "PG",
    "DialingCode": "+675"
  },
  {
    "Id": 170,
    "Name": "Paraguay",
    "CountryCode": "PY",
    "DialingCode": "+595"
  },
  {
    "Id": 171,
    "Name": "Peru",
    "CountryCode": "PE",
    "DialingCode": "+51"
  },
  {
    "Id": 172,
    "Name": "Philippines",
    "CountryCode": "PH",
    "DialingCode": "+63"
  },
  {
    "Id": 174,
    "Name": "Poland",
    "CountryCode": "PL",
    "DialingCode": "+48"
  },
  {
    "Id": 175,
    "Name": "Portugal",
    "CountryCode": "PT",
    "DialingCode": "+351"
  },
  {
    "Id": 177,
    "Name": "Qatar, State of",
    "CountryCode": "QA",
    "DialingCode": "+974 "
  },
  {
    "Id": 178,
    "Name": "Reunion (French) (Former Bourbon Island)",
    "CountryCode": "RE",
    "DialingCode": "+262"
  },
  {
    "Id": 179,
    "Name": "Romania",
    "CountryCode": "RO",
    "DialingCode": "+40"
  },
  {
    "Id": 181,
    "Name": "Russian Federation",
    "CountryCode": "RU",
    "DialingCode": "+7"
  },
  {
    "Id": 182,
    "Name": "Rwanda (Rwandese Republic) (Former Ruanda)",
    "CountryCode": "RW",
    "DialingCode": "+250"
  },
  {
    "Id": 183,
    "Name": "Saint Helena",
    "CountryCode": "SH",
    "DialingCode": "+290"
  },
  {
    "Id": 184,
    "Name": "Saint Kitts and Nevis (Former Federation of Saint Christopher and Nevis)",
    "CountryCode": "KN",
    "DialingCode": "+1-869"
  },
  {
    "Id": 185,
    "Name": "Saint Lucia",
    "CountryCode": "LC",
    "DialingCode": "+1-758"
  },
  {
    "Id": 186,
    "Name": "Saint Pierre and Miquelon",
    "CountryCode": "PM",
    "DialingCode": "+508"
  },
  {
    "Id": 187,
    "Name": "Saint Vincent and the Grenadines",
    "CountryCode": "VC",
    "DialingCode": "+1-784"
  },
  {
    "Id": 188,
    "Name": "Samoa (Former Western Samoa)",
    "CountryCode": "WS",
    "DialingCode": "+685"
  },
  {
    "Id": 189,
    "Name": "San Marino",
    "CountryCode": "SM",
    "DialingCode": "+378"
  },
  {
    "Id": 190,
    "Name": "Sao Tome and Principe",
    "CountryCode": "ST",
    "DialingCode": "+239"
  },
  {
    "Id": 191,
    "Name": "Saudi Arabia",
    "CountryCode": "SA",
    "DialingCode": "+966"
  },
  {
    "Id": 193,
    "Name": "Senegal",
    "CountryCode": "SN",
    "DialingCode": "+221"
  },
  {
    "Id": 194,
    "Name": "Seychelles",
    "CountryCode": "SC",
    "DialingCode": "+248"
  },
  {
    "Id": 195,
    "Name": "Sierra Leone",
    "CountryCode": "SL",
    "DialingCode": "+232"
  },
  {
    "Id": 196,
    "Name": "Singapore",
    "CountryCode": "SG",
    "DialingCode": "+65"
  },
  {
    "Id": 197,
    "Name": "Slovakia",
    "CountryCode": "SK",
    "DialingCode": "+421"
  },
  {
    "Id": 198,
    "Name": "Slovenia",
    "CountryCode": "SI",
    "DialingCode": "+386"
  },
  {
    "Id": 199,
    "Name": "Solomon Islands (Former British Solomon Islands)",
    "CountryCode": "SB",
    "DialingCode": "+677"
  },
  {
    "Id": 200,
    "Name": "Somalia (Former Somali Republic, Somali Democratic Republic)",
    "CountryCode": "SO",
    "DialingCode": "+252"
  },
  {
    "Id": 201,
    "Name": "South Africa (Former Union of South Africa)",
    "CountryCode": "ZA",
    "DialingCode": "+27"
  },
  {
    "Id": 203,
    "Name": "Spain",
    "CountryCode": "ES",
    "DialingCode": "+34"
  },
  {
    "Id": 204,
    "Name": "Sri Lanka (Former Serendib, Ceylon)",
    "CountryCode": "LK",
    "DialingCode": "+94"
  },
  {
    "Id": 205,
    "Name": "Sudan (Former Anglo-Egyptian Sudan)",
    "CountryCode": "SD",
    "DialingCode": "+249"
  },
  {
    "Id": 206,
    "Name": "Suriname (Former Netherlands Guiana, Dutch Guiana)",
    "CountryCode": "SR",
    "DialingCode": "+597"
  },
  {
    "Id": 208,
    "Name": "Swaziland, Kingdom of",
    "CountryCode": "SZ",
    "DialingCode": "+268"
  },
  {
    "Id": 209,
    "Name": "Sweden",
    "CountryCode": "SE",
    "DialingCode": "+46"
  },
  {
    "Id": 210,
    "Name": "Switzerland",
    "CountryCode": "CH",
    "DialingCode": "+41"
  },
  {
    "Id": 211,
    "Name": "Syria (Syrian Arab Republic) (Former United Arab Republic - with Egypt)",
    "CountryCode": "SY",
    "DialingCode": "+963"
  },
  {
    "Id": 212,
    "Name": "Taiwan (Former Formosa)",
    "CountryCode": "TW",
    "DialingCode": "+886"
  },
  {
    "Id": 213,
    "Name": "Tajikistan (Former Tajik Soviet Socialist Republic)",
    "CountryCode": "TJ",
    "DialingCode": "+992"
  },
  {
    "Id": 214,
    "Name": "Tanzania, United Republic of (Former United Republic of Tanganyika and Zanzibar)",
    "CountryCode": "TZ",
    "DialingCode": "+255"
  },
  {
    "Id": 215,
    "Name": "Thailand (Former Siam)",
    "CountryCode": "TH",
    "DialingCode": "+66"
  },
  {
    "Id": 217,
    "Name": "Tokelau",
    "CountryCode": "TK",
    "DialingCode": "+690"
  },
  {
    "Id": 218,
    "Name": "Tonga, Kingdom of (Former Friendly Islands)",
    "CountryCode": "TO",
    "DialingCode": "+676"
  },
  {
    "Id": 219,
    "Name": "Trinidad and Tobago",
    "CountryCode": "TT",
    "DialingCode": "+1-868"
  },
  {
    "Id": 221,
    "Name": "Tunisia",
    "CountryCode": "TN",
    "DialingCode": "+216"
  },
  {
    "Id": 222,
    "Name": "Turkey",
    "CountryCode": "TR",
    "DialingCode": "+90"
  },
  {
    "Id": 223,
    "Name": "Turkmenistan (Former Turkmen Soviet Socialist Republic)",
    "CountryCode": "TM",
    "DialingCode": "+993"
  },
  {
    "Id": 224,
    "Name": "Turks and Caicos Islands",
    "CountryCode": "TC",
    "DialingCode": "+1-649"
  },
  {
    "Id": 225,
    "Name": "Tuvalu (Former Ellice Islands)",
    "CountryCode": "TV",
    "DialingCode": "+688"
  },
  {
    "Id": 226,
    "Name": "Uganda, Republic of",
    "CountryCode": "UG",
    "DialingCode": "+256"
  },
  {
    "Id": 227,
    "Name": "Ukraine (Former Ukrainian National Republic, Ukrainian State, Ukrainian Soviet Socialist Republic)",
    "CountryCode": "UA",
    "DialingCode": "+380"
  },
  {
    "Id": 228,
    "Name": "United Arab Emirates (UAE) (Former Trucial Oman, Trucial States)",
    "CountryCode": "AE",
    "DialingCode": "+971"
  },
  {
    "Id": 229,
    "Name": "United Kingdom (Great Britain / UK)",
    "CountryCode": "GB",
    "DialingCode": "+44"
  },
  {
    "Id": 230,
    "Name": "United States",
    "CountryCode": "US",
    "DialingCode": "+1"
  },
  {
    "Id": 232,
    "Name": "Uruguay, Oriental Republic of (Former Banda Oriental, Cisplatine Province)",
    "CountryCode": "UY",
    "DialingCode": "+598"
  },
  {
    "Id": 233,
    "Name": "Uzbekistan (Former UZbek Soviet Socialist Republic)",
    "CountryCode": "UZ",
    "DialingCode": "+998"
  },
  {
    "Id": 234,
    "Name": "Vanuatu (Former New Hebrides)",
    "CountryCode": "VU",
    "DialingCode": "+678"
  },
  {
    "Id": 235,
    "Name": "Vatican City State (Holy See)",
    "CountryCode": "VA",
    "DialingCode": "+418"
  },
  {
    "Id": 236,
    "Name": "Venezuela",
    "CountryCode": "VE",
    "DialingCode": "+58"
  },
  {
    "Id": 237,
    "Name": "Vietnam",
    "CountryCode": "VN",
    "DialingCode": "+84"
  },
  {
    "Id": 238,
    "Name": "Virgin Islands, British",
    "CountryCode": "VI",
    "DialingCode": "+1-284"
  },
  {
    "Id": 239,
    "Name": "Virgin Islands, United States (Former Danish West Indies)",
    "CountryCode": "VQ",
    "DialingCode": "+1-340"
  },
  {
    "Id": 240,
    "Name": "Wallis and Futuna Islands",
    "CountryCode": "WF",
    "DialingCode": "+681"
  },
  {
    "Id": 242,
    "Name": "Yemen",
    "CountryCode": "YE",
    "DialingCode": "+967"
  },
  {
    "Id": 245,
    "Name": "Zambia, Republic of (Former Northern Rhodesia)",
    "CountryCode": "ZM",
    "DialingCode": "+260"
  },
  {
    "Id": 246,
    "Name": "Zimbabwe, Republic of (Former Southern Rhodesia, Rhodesia)",
    "CountryCode": "ZW",
    "DialingCode": "+263"
  },
  {
    "Id": 247,
    "Name": "Puerto Rico",
    "CountryCode": "PR",
    "DialingCode": "+1-787"
  },
  {
    "Id": 248,
    "Name": "Puerto Rico",
    "CountryCode": "PR",
    "DialingCode": "+1-939"
  },
  {
    "Id": 249,
    "Name": "Dominican Republic",
    "CountryCode": "DO",
    "DialingCode": "+1-809"
  },
  {
    "Id": 250,
    "Name": "Dominican Republic",
    "CountryCode": "DO",
    "DialingCode": "+1-829"
  }
];
