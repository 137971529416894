import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-reset-password-sent',
  templateUrl: './reset-password-sent.component.html',
  styleUrls: ['./reset-password-sent.component.scss']
})
export class ResetPasswordSentComponent implements OnInit {
  mail: '';
  constructor(public authService: AuthService,
              private activatedRoute: ActivatedRoute,
              ) {

    const queryParams: any = this.activatedRoute.snapshot.queryParams;
    this.mail = queryParams.mail;
  }

  ngOnInit(): void {
  }

}
