import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-success-password-reset',
  templateUrl: './success-password-reset.component.html',
  styleUrls: ['./success-password-reset.component.scss']
})
export class SuccessPasswordResetComponent implements OnInit {

  constructor(public authService: AuthService) { }

  ngOnInit(): void {
  }

}
