import { NgModule } from '@angular/core';
import {CommonModule, CurrencyPipe, DatePipe} from '@angular/common';
import {SharedService} from "./services/shared.service";
import {MatTabsModule} from "@angular/material/tabs";
import {MonetaryFormatterPipe} from "./pipes/monetary-formatter.pipe";
import {BaseModal} from "./components/modal/base-modal";
import {DebugPanelComponent} from "./components/debug-panel/debug-panel.component";
import {ShowErrorsComponent} from "./components/show-errors/show-errors.component";
import {CustomValidators} from "./services/custom-validators";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {CountdownModule} from "ngx-countdown";
import {ToastrModule} from "ngx-toastr";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ConfirmationPopupComponent} from "./components/confirmation-popup/confirmation-popup.component";
import { NinFormatterPipe } from './pipes/nin-formatter.pipe';
import { InvoiceFilterPipe } from './pipes/invoice-filter.pipe';
import {AutoAddProtocolPipe} from "./pipes/auto-add-protocol.pipe";
import { MembershipInvoiceFilterPipe } from './pipes/membership-invoice-filter.pipe';
import { MembershipInvoiceYearExtractorPipe } from './pipes/membership-invoice-year-extractor.pipe';
import {NgxSpinnerModule} from "ngx-spinner";



@NgModule({
  declarations: [
    MonetaryFormatterPipe,
    BaseModal,
    DebugPanelComponent,
    ShowErrorsComponent,
    ConfirmationPopupComponent,
    NinFormatterPipe,
    InvoiceFilterPipe,
    AutoAddProtocolPipe,
    MembershipInvoiceFilterPipe,
    MembershipInvoiceYearExtractorPipe
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    TooltipModule.forRoot(),
    NgxSpinnerModule,
    CountdownModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    })
  ],
  exports: [
    MatTabsModule,
    MonetaryFormatterPipe,
    DebugPanelComponent,
    ShowErrorsComponent,
    TooltipModule,
    CountdownModule,
    NgxSpinnerModule,
    ToastrModule,
    NinFormatterPipe,
    InvoiceFilterPipe,
    AutoAddProtocolPipe,
    MembershipInvoiceFilterPipe,
    MembershipInvoiceYearExtractorPipe
  ],
  providers: [
    SharedService,
    MonetaryFormatterPipe,
    CurrencyPipe,
    CustomValidators,
  ]
})
export class SharedModule { }
