<ngx-spinner bdColor="rgb(3 86 158 / 80%)" size="medium" color="#fff" type="ball-scale-ripple-multiple"
             [fullScreen]="true"><p style="color: white"> Bekrefter link... </p></ngx-spinner>
<div class="container h-100" *ngIf="verifiedEmail">
  <div class="row h-100 justify-content-center align-items-center">
    <div class="col-12 form-horizontal min-signup-form mb-55">
      <div class=" row">
        <div class="col-md-12 pb-3 min-heading-sec">
          <h3>Nytt passord for <br>
            {{verifiedEmail}}</h3>
        </div>
      </div>
      <div [formGroup]="fg" class="row">
        <div class="col-md-12 pb-4">
          <div>
            <label>Passord (minst 8 tegn):</label>
            <div class="text-right hint-container" style="height: 1px; position: relative;top: -26px;">
              <ng-template #popTemplate>
                <div class="tooltip-content">
                  <div style="display: flex">
                    <i class="bi pr-2" [ngClass]="{
                    'bi-check-circle text-success': cValidator.hasMinCountEight(ctrlPassword.value),
                    'bi-x-circle text-danger': !cValidator.hasMinCountEight(ctrlPassword.value)
                    }"></i>
                    <p class="m-0">
                      Passordet må være på minst 8 tegn
                      <br> og inneholde minst tre av følgende:
                      <!--The password must be at least 8 characters long and contain at least three of the following:-->
                    </p>
                  </div>
                  <ul class="list-unstyled pl-5">
                    <li>- <i class="bi" [ngClass]="{
                    'bi-check-circle text-success': cValidator.hasCapital(ctrlPassword.value),
                    'bi-x-circle text-danger': !cValidator.hasCapital(ctrlPassword.value)
                    }"></i> en stor bokstav
                    </li> <!--A CAPITAL LETTER-->
                    <li>- <i class="bi" [ngClass]="{
                    'bi-check-circle text-success': cValidator.hasSmall(ctrlPassword.value),
                    'bi-x-circle text-danger': !cValidator.hasSmall(ctrlPassword.value)
                    }"></i> en liten bokstav
                    </li> <!--A LOWER CASE-->
                    <li>- <i class="bi" [ngClass]="{
                    'bi-check-circle text-success': cValidator.hasNumber(ctrlPassword.value),
                    'bi-x-circle text-danger': !cValidator.hasNumber(ctrlPassword.value)
                    }"></i> et tall
                    </li><!--A NUMBER-->
                    <li>- <i class="bi" [ngClass]="{
                    'bi-check-circle text-success': cValidator.hasSymbol(ctrlPassword.value),
                    'bi-x-circle text-danger': !cValidator.hasSymbol(ctrlPassword.value)
                    }"></i> et symbol
                    </li><!--A SYMBOL-->
                  </ul>
                </div>

              </ng-template>
              <a #REF_TOOPTIP_ELEM="bs-tooltip" triggers="none" (click)="onTooltipClick()" [tooltip]="popTemplate" placement="right" href="javascript:void(0)" style="cursor: pointer">
                <i   class="bi bi-info-circle" [ngClass]="{'trigger-tooltip': triggerTooltip(), 'text-danger':  ctrlPassword.dirty && ctrlPassword.invalid, 'text-success':  ctrlPassword.dirty && ctrlPassword.valid}"></i>
              </a>

            </div>
          </div>
          <div class="input-group with-focus">
            <input formControlName="password" type="password"
                   [ngClass]="{'is-invalid':  ctrlPassword.dirty && ctrlPassword.invalid, 'is-valid':  ctrlPassword.dirty && ctrlPassword.valid}"
                   placeholder="********" class="form-control">
          </div>
        </div>
        <div class="col-md-12 pb-4">
          <label>Gjenta passord:</label>
          <div class="input-group with-focus">
            <input formControlName="retypePassword"
                   [ngClass]="{'is-invalid':  (ctrlRetypePassword.dirty  && !isValidRetypePswd()), 'is-valid':  ctrlRetypePassword.dirty && isValidRetypePswd()}"
                   placeholder="********" [type]="viewPswd?'text': 'password'" class="form-control">
            <div class="st-password-view-wrap">
              <a href="javascript:void(0)"
                 (mousedown)="onViewPassword($event)"
                 (touchstart)="onViewPassword($event)"
                 (mouseup)="onViewPassword($event, true)"
                 (touchend)="onViewPassword($event,true)"
              ><i class="bi bi-eye-slash" [ngClass]="{'bi-eye-slash': !viewPswd, 'bi-eye': viewPswd}"></i></a>
            </div>

          </div>
        </div>
      </div>

      <div class=" row">
        <!--<div class="pswd-hint col-md-12">
          <p class="m-0">
            Passordet må være på minst 8 tegn og inneholde minst tre av følgende:
          </p>
          <ul class="list-unstyled pl-5">
            <li>- en stor bokstav</li>
            <li> - en liten bokstav</li>
            <li>- et tall</li>
            <li>- et symbol</li>
          </ul>
        </div>-->
        <p class="col-md-12 mb-5 mt-4">Når du har bekreftet nytt passord, blir du sendt til innloggings-siden hvor du må
          skrive epost og passord for å logge på.

        </p>


      </div>
      <div class=" row">
        <div class="col-md-12 text-center">
          <button [ngClass]="{'ajax-loader-2' : inProgress}"
                  [disabled]="inProgress || fg.invalid || ctrlPassword.value != ctrlRetypePassword.value"
                  (click)="onConfirmPasswordClick()" mat-raised-button class="btn-submit btn-yellow mb-5">Bekreft
            passord
          </button>
          <a class="mt-5 link-blue" (click)="authService.routeToLogin()">Tilbake til innlogging</a>
        </div>

      </div>

    </div>
  </div>
</div>



