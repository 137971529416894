import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import {RouterModule, Routes} from "@angular/router";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { GoogleMapsModule } from '@angular/google-maps';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';

import {SharedModule} from "./modules/shared/shared.module";
import {AuthGuard} from "./modules/shared/guards/auth.guard";
import {ReactiveFormsModule} from "@angular/forms";
import { PerfectScrollbarModule, PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar2';
import { HttpHeaderInterceptor } from './services/httpinterceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpHelperService } from './services/http-helper.service';
import { AuthService } from './services/auth.service';
import {ModalModule} from "ngx-modialog-7";
import {BootstrapModalModule} from "ngx-modialog-7/plugins/bootstrap";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {AgmCoreModule} from "@agm/core";
import {NgxSpinnerModule} from "ngx-spinner";
import { SignupComponent } from './components/signup/signup.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import {MatButtonModule} from "@angular/material/button";
import { LoginComponent } from './components/login/login.component';
import { VerificationSentComponent } from './components/verification-sent/verification-sent.component';
import { InvalidLinkComponent } from './components/invalid-link/invalid-link.component';
import { ProcessVerificationComponent } from './components/process-verification/process-verification.component';
import { SuccessAccountCreationComponent } from './components/success-account-creation/success-account-creation.component';
import { SuccessPasswordResetComponent } from './components/success-password-reset/success-password-reset.component';
import { ResetPasswordSentComponent } from './components/reset-password-sent/reset-password-sent.component';



/**
* Set your default interaction type for MSALGuard here. If you have any
* additional scopes you want the user to consent upon login, add them here as well.
*/


const routes: Routes = [
  { path: '', component: SignupComponent },
 /* { path: 'login', component: LoginComponent},*/
  { path: 'signup', component: SignupComponent},
  { path: 'verification', component: VerificationSentComponent}, /* { path: 'reset-password', component: ResetPasswordComponent},*/

  { path: 'reset-password', component: ResetPasswordComponent}, /* { path: 'reset-password', component: ResetPasswordComponent},*/
  { path: 'reset-link-sent', component: ResetPasswordSentComponent}, /* { path: 'reset-password', component: ResetPasswordComponent},*/
  { path: 'verify-link', component: ProcessVerificationComponent},
  { path: 'invalid-link', component: InvalidLinkComponent},
  { path: 'forgot-password', component: ForgotPasswordComponent},

  { path: 'account-created', component: SuccessAccountCreationComponent},
  { path: 'password-changed', component: SuccessPasswordResetComponent},
  { path: '**', redirectTo: 'signup' },
];

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;


@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    LoginComponent,
    VerificationSentComponent,
    InvalidLinkComponent,
    ProcessVerificationComponent,
    SuccessAccountCreationComponent,
    SuccessPasswordResetComponent,
    ResetPasswordSentComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    SharedModule,
    ReactiveFormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    MatButtonModule,
    BootstrapModalModule,
    NgxSpinnerModule,

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpHeaderInterceptor, multi: true },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    AuthService,
    HttpHelperService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
