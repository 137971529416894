import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';
import { catchError, switchMap } from 'rxjs/operators';
import {API_TYPE, HttpHelperService} from "./http-helper.service";


/** Pass untouched request through to the next request handler. */
@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService, private httpHelper: HttpHelperService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {
      // EXCEPTIONAL CONDITION FOR VERIFY APP API CALLS
      let apiType = this.httpHelper.getApiTypeFromUrl(req.url);
      apiType = API_TYPE.INCLUDE_SUBSCRIPTION_KEY;
      var newHeaderUpdate = this.updateAuthHeader(apiType);
      return next.handle(req.clone(newHeaderUpdate));
    }

    // This method add token to request headers
  updateAuthHeader(apiType?: any): any {
    // Update headers with access token
    const headerObj: any = {};
    if(apiType == API_TYPE.INCLUDE_SUBSCRIPTION_KEY){
      headerObj['subscription-key'] =  environment.subscriptionKey;
    }
    const newHeaderUpdate = {
      setHeaders: headerObj
    };
    return newHeaderUpdate;
  }
}
